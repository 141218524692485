.Footer-container > div {
  position: relative;
  left: 6rem;
}
@media screen and (max-width: 768px) {
  .Footer-container {
    left: -5rem;
    position: relative;
  }
}
